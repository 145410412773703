import ourTeams from "../assets/ourTeams2.jpg";

export const FactsContainer = ({background="white", heading="", items=[]})=>{
    return(
        <div className='factsDiv1' 
            style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)),url(${ourTeams}) no-repeat center`,
                backgroundSize: "cover",
                backgroundPositionY:"67%"
            }}
        >
            <h2 >{heading}</h2>
            <div className="factsDiv2" >
                <div className="factsDiv3" >
                    {items.map(item=>{
                        return(
                            <div >
                                {item.icon}
                                <h5 style={{color:"black", fontWeight:"bold", fontSize:"large"}}>{item.role}</h5>
                                <h6 style={{color:"#464646", fontSize:"large", fontWeight:"bold"}}>{item.name}</h6>
                                <h6 style={{color:"#464646", fontSize:"large"}}>{item.college}</h6>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}