import { Radio } from "antd";

export const Radios = ({onChange=()=>"", value=""})=>{
    return(
        <Radio.Group onChange={onChange} value={value} style={{fontSize:"15px"}} buttonStyle="outline">
            <Radio value={"Guitar"} style={{color:"white", fontSize:"15px"}}>Guitar</Radio>
            <Radio value={"Piano"} style={{color:"white", fontSize:"15px"}}>Piano</Radio>
            <Radio value={"Ukulele"} style={{color:"white", fontSize:"15px"}}>Ukulele</Radio>
            <Radio value={"Music"} style={{color:"white", fontSize:"15px"}}>Music</Radio>
        </Radio.Group>
    )
}