import { Menu, Layout } from "antd";
import { StyledDiv } from "./styled/styledDiv";
import { StyledMenu } from "./styled/styledMenu";
import treble from '../assets/trebletop.png'
import { MenuOutlined } from "@ant-design/icons";
const {Header} = Layout

export const TopNav = ({title="Treble", contents=[]})=>{
    return(
        <Header className="topHeader" style={{height:"15%",padding:"0px", position: 'fixed', zIndex: 10, width: '100%', display:"flex", flexDirection:"row", justifyContent:"center", backgroundColor:"black"}}>
            <div className='topNavLogo'><img src={treble} style={{width:"100%"}}/></div>
            <StyledMenu overflowedIndicator={<MenuOutlined/>} mode="horizontal" theme={"dark"} className='topHeaderMenu' style={{backgroundColor:"black", display:"flex", justifyContent:"right", paddingRight:"2%", width:"75vw"}}>
                {contents.map((item, index)=>{
                    return(
                        <Menu.Item key={index}>
                            <a href={`#${item.id}`}><StyledDiv color="white">{item.text}</StyledDiv></a>
                        </Menu.Item>
                    )
                })}
            </StyledMenu>
        </Header>
    )
}