import { Input, Button, message} from 'antd';
import { useState } from 'react';
import { Radios } from './radio';
import { StyledForm } from './styled/styledForm';
import axios from 'axios';

axios.defaults.headers.common = {
    "X-API-Key": "IWfsYUniaRIy9YWGhSlixUApOqc@aoa%BJCFK1yALppeqJqNZiTo#i-k#0qGDAu%",
  };
export const DemoForm = ({color="transparent"})=>{

    const [name, setName] = useState('');
    const [number, setNumber] = useState();
    const [email, setEmail] = useState('');
    const [course, setCourse] = useState('');
    const [state, setState] = useState('');

    const success = () => {
        message.success('Your data send succesfully.');
    };

    const [form] = StyledForm.useForm();

    const onChangeCourse = (e)=>{
        setCourse(e.target.value)
      }

    const onFinish = ()=>{
        const currentdate = new Date();
        const datetime = currentdate.getDay() + "/" + currentdate.getMonth() 
        + "/" + currentdate.getFullYear() + " " 
        + currentdate.getHours() + ":" 
        + currentdate.getMinutes() + ":" + currentdate.getSeconds();
        const url = 'https://sheet.best/api/sheets/73891aef-b2fd-4c80-80c2-8d4edd7db13a';
        axios.post(url,
            {"Timestamp":datetime.toString(),"Name of your student":name,"Parents Phone Number ":number,"Email ID ":email,"Preferred Course ":course,"State\/Region":state}
            ).catch(error=>{
                throw error
            }).finally(()=>{
                success();
                setCourse('')
                setEmail('')
                setName('')
                setNumber('')
                setState('')
                form.resetFields();
            })
    }
    
    return (
        <StyledForm
            form={form}
            style={{backgroundColor:color, width:"100%", fontSize:"larger", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}
            name='Book Your Free Demo'
            labelCol={{span:24}}
            wrapperCol={{span:24}}
            onFinish={onFinish}
            target={"hidden_iframe"}
            onFinishFailed={()=>''} colon={false} method={'POST'} 
            action={"https://docs.google.com/forms/d/e/1FAIpQLScS-Wj-2B7aqPxxSx8ACLmSlqnIO3EqfC3Z1gMnGinyfdja4w/formResponse"}>
            <StyledForm.Item style={{width:"60%"}} label={<p style={{color:"white", fontSize:"15px"}}>Name</p>} name={"name"}  rules={[{ required: true, message: 'Please input your name!' }]}>
                <Input placeholder='Enter Student Name...' size='large' style={{border:"2px solid white", background:"#00000059",color:"white"}} value={name} onChange={(e)=>setName(e.target.value)}/>
            </StyledForm.Item>
            <StyledForm.Item style={{width:"60%"}} label={<p style={{color:"white", fontSize:"15px"}}>Phone Number</p>} name={"phoneNumber"}  rules={[{ required: true, message: 'Please input your phone number!' }]}>
                <Input placeholder='Enter Mobile Number...' size='large' style={{border:"2px solid white",background:"#00000059",color:"white"}} value={number} onChange={(e)=>setNumber(e.target.value)}/>
            </StyledForm.Item>
            <StyledForm.Item style={{width:"60%"}} label={<p style={{color:"white", fontSize:"15px"}}>Email</p>} name={"email"}  rules={[{ required: true, message: 'Please input your email!' }]}>
                <Input placeholder='Enter Email Address...' size='large' style={{border:"2px solid white", background:"#00000059",color:"white"}} value={email} onChange={(e)=>setEmail(e.target.value)}/>
            </StyledForm.Item>
            <StyledForm.Item style={{width:"60%"}} label={<p style={{color:"white", fontSize:"15px"}}>Select Course</p>} name={"course"}  rules={[{ required: true, message: 'Please select your course!' }]}>
                <Radios onChange={onChangeCourse} value={course}/>
            </StyledForm.Item>
            <StyledForm.Item style={{width:"60%"}} label={<p style={{color:"white", fontSize:"15px"}}>State/Region</p>} name={"stateRegion"}  rules={[{ required: true, message: 'Please input your region!' }]}>
                <Input placeholder='Enter State/Region...' size='large' style={{border:"2px solid white", background:"#00000059",color:"white"}} value={state} onChange={(e)=>setState(e.target.value)}/>
            </StyledForm.Item>
            <StyledForm.Item style={{width:"100%"}}>
                <Button htmlType='submit' style={{backgroundColor:"rgb(0 0 0 / 30%)", color:"white", width:"fit-content", fontWeight:"bold"}} onClick={()=>()=>onFinish}>Submit</Button>
            </StyledForm.Item>
        </StyledForm>
    )
}