import Layout from "antd/lib/layout/layout";
import { useState } from "react";
import "../node_modules/antd/dist/antd.css";
import "./App.css";
import { FactsContainer } from "./components/factsContainer";
import { FooterDiv } from "./components/footer";
import { DemoForm } from "./components/form";
import { TopNav } from "./components/header";
import { Services } from "./components/services";
import piano from "./assets/piano.jpg";
import guitar from "./assets/guitar.jpg";
import studentOfTheSeason from "./assets/studentOfTheSeason.jpg";
import ukulele from "./assets/ukulele.jpg";
import anfas from "./assets/anfas.png";
import ajil from "./assets/ajil.JPG";
import bgForm from "./assets/form_bg.jpg";
import vocal from "./assets/vocal.jpg";
import rookie from "./assets/rookie.jpg";
import intermediate from "./assets/intermediate.jpg";
import pro from "./assets/pro.jpg";
import announcement from "./assets/announcement.jpg";
import ReactPlayer from "react-player";
// import trebleT from './assets/trebleTBNeon.png.svg'

<style>@import url('http://fonts.cdnfonts.com/css/helvetica-neue-9');</style>;

function App() {
  const [value, setValue] = useState(false);

  const content = `Treble India is an Indian online music learning platform formed in the year 2021. We believe that education should not be a privilege, but rather an opportunity for everyone. Learning has never been more enjoyable, and lessons have never been more accessible, thanks to cutting-edge TREBLE currently operates in many parts of India, with its headquarters in Kochi Kerala, and wants to educate as many people as possible in the near future.`;
  const items = [
    {
      icon: (
        <div style={{ width: "90%", alignSelf: "center", height: "75%" }}>
          <img
            src={anfas}
            style={{ borderRadius: "50%", width: "100%", height: "100%" }}
          ></img>
        </div>
      ),
      name: "Anfas",
      role: "Web Designer",
      college: "IIT, Chennai",
    },
    {
      icon: (
        <div style={{ width: "90%", alignSelf: "center", height: "75%" }}>
          <img
            src={ajil}
            style={{ borderRadius: "50%", width: "100%", height: "100%" }}
          ></img>
        </div>
      ),
      name: "Ajil V Sajith",
      role: "Content Creator",
      college: "Centennial, Canada",
    },
  ];
  const GOOGLE_FORM_MESSAGE_ID = "entry.124963602";
  const GOOGLE_FORM_EMAIL_ID = "entry.1157005189";
  const GOOGLE_FORM_ACTION_URL =
    "https://docs.google.com/forms/d/e/1FAIpQLScS-Wj-2B7aqPxxSx8ACLmSlqnIO3EqfC3Z1gMnGinyfdja4w/viewform?usp=embed_googleplus";
  const CORS_PROXY = "https://cors-escape.herokuapp.com/";

  const onFinish = () => {};

  const navContents = [
    {
      text: (
        <span>
          Book Your <span style={{ color: "#e94c62" }}>Free</span> Demo
        </span>
      ),
      id: "form",
    },
    { text: "Courses", id: "courses" },
    { text: "Curriculum", id: "curriculum" },
    { text: "Our Team", id: "ourTeam" },
    { text: "About Us", id: "aboutUs" },
    { text: "Contact Us", id: "contactUs" },
  ];

  return (
    <div
      className="App"
      style={{ fontFamily: "Helvetica Neue, sans-serif;", fontWeight: "bold" }}
    >
      <Layout>
        <TopNav contents={navContents} />
        <section id="form" style={{ backgroundColor: "rgb(0 1 16)" }}>
          <div className="topNavDiv" style={{
            width: "100%",
            background: `url(${bgForm}) no-repeat center`,
            backgroundSize: "70% 60%",
            backgroundPositionY: "65%",
            backgroundSize: "cover",
          }}>
            <div className="topNavDiv2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  // background: `url(${bgForm}) no-repeat center`,
                  // backgroundSize: "70% 60%",
                  // backgroundPositionY: "65%",
                  // backgroundSize: "cover",
                }}
              >
                <div className="topNavDiv4">
                  <div>
                    <div className="topNavDiv6">
                      <h1>
                        BOOK YOUR <span>FREE</span> DEMO
                      </h1>
                    </div>
                    <div className="topNavdiv7">
                      <DemoForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h1
                style={{
                  color: "white",
                  fontSize: "xxx-large",
                  fontWeight: "bold",
                }}
              >
                Do it now.
              </h1>
            </div>
          </div>
        </section>
        <section id="courses" style={{ backgroundColor: "rgb(255 213 227)" }}>
          <div className="coursesDiv1">
            <div className="coursesDiv2">
              <h1>Our Courses</h1>
              {/* <p className="ourCoursesDesc">
                <i style={{color:"rgb(151 151 151)"}}>"I AM ONLY MYSLEF WHEN HAVE A GUITAR IN MY HANDS"</i>
                <span style={{ width:"fit-content",alignSelf: "flex-end",color:"rgb(151 151 151)"}}>- GEORGE HARRISON</span>
              </p> */}
              <div className="startingQuote02">
                <span className="quotes" style={{justifyContent:"start", paddingLeft:"10%"}}>&#8220;</span>
                <h3>I am only myself when I have a guitar in my hand.</h3>
                <h4>- GEORGE HARRISON</h4>
                <span className="quotes" style={{justifyContent:"end", paddingRight:"10%"}}>&#8221;</span>
            </div>
              <p
                className="ourCoursesDescSub"
                style={{textAlign:"center"}}
              >
                Here in treble we offer courses from scratch to advanced levels.
                Also our newly formed custom menu offers students to customise
                their own special course.
              </p>
            </div>
            <div className="coursesDiv3">
              <div className="coursesDiv4">
                <div
                  className="courseThumbnail"
                  style={{
                    background: `url(${guitar}) no-repeat center`,
                    backgroundSize: "cover",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="courseContent">
                      <h1 style={{ fontSize: "xxx-large", fontWeight: "bold", color:"white" }}>
                        Guitar
                        <span
                          style={{
                            fontFamily: "Satisfy, cursive",
                            fontSize: "xx-large",
                            color: "#b75ac0",
                          }}
                        >
                          <p>Acoustic</p>
                        </span>
                      </h1>
                    </div>
                    <div className="registerNowDiv">
                        <a
                          href="#form"
                          className="registerNowBtn"
                        >
                          Register Now
                        </a>
                    </div>
                  </div>
                </div>
                <div
                  className="courseThumbnail"
                  style={{
                    background: `url(${piano}) no-repeat center`,
                    backgroundSize: "cover",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="courseContent">
                      <h1 style={{ fontSize: "xxx-large", fontWeight: "bold", color:"white"}}>
                        Piano
                        <span
                          style={{
                            fontFamily: "Satisfy, cursive",
                            fontSize: "xx-large",
                            color: "#b75ac0",
                          }}
                        >
                          <p>Western</p>
                        </span>
                      </h1>
                    </div>
                    <div className="registerNowDiv">
                        <a
                          href="#form"
                          className="registerNowBtn"
                        >
                          Register Now
                        </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coursesDiv5">
                <div
                  className="courseThumbnail"
                  style={{
                    background: `url(${ukulele}) no-repeat center`,
                    backgroundSize: "cover",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="courseContent">
                      <h1 style={{ fontSize: "xxx-large", fontWeight: "bold", color:"white" }}>
                        Ukulele
                        <span
                          style={{
                            fontFamily: "Satisfy, cursive",
                            fontSize: "xx-large",
                            color: "#b75ac0",
                          }}
                        >
                        </span>
                      </h1>
                    </div>
                    <div className="registerNowDiv">
                        <a
                          href="#form"
                          className="registerNowBtn"
                        >
                          Register Now
                        </a>
                    </div>
                  </div>
                </div>
                <div
                  className="courseThumbnail"
                  style={{
                    background: `url(${vocal}) no-repeat center`,
                    backgroundSize: "cover",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="courseContent">
                      <h1 style={{ fontSize: "xxx-large", fontWeight: "bold", color:"white"}}>
                        Music
                        <span
                          style={{
                            fontFamily: "Satisfy, cursive",
                            fontSize: "xx-large",
                            color: "#b75ac0",
                          }}
                        >
                          <p>Carnatic</p>
                        </span>
                      </h1>
                    </div>
                    <div className="registerNowDiv">
                        <a
                          href="#form"
                          className="registerNowBtn"
                        >
                          Register Now
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="studentOfTheSeason" style={{ backgroundColor: "rgb(68, 202, 242)" }}>
          <div className="studentOfTheSeasonDiv0" 
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)),url(${studentOfTheSeason}) no-repeat center`,
              backgroundSize: "cover"
            }}
          >
            <div className="studentOfTheSeasonDiv1">
              <h1>Student of the &nbsp;
                <span className="sots-emphasis" style={{
                            fontFamily: "Satisfy, cursive",
                            color: "rgb(255, 185, 0)",
                          }}>
                  Season
                </span>
              </h1>
            </div>
            <div className="studentOfTheSeasonDiv02">
                <span className="quotes" style={{justifyContent:"start", paddingLeft:"3%"}}>&#8220;</span>
                <h3>I love to play guitar, I learned  lots of notes through Treble  and understood everything.</h3>
                <h4>- John</h4>
                <span className="quotes" style={{justifyContent:"end", paddingRight:"3%"}}>&#8221;</span>
            </div>
            <div className="studentOfTheSeasonDiv2">
              <ReactPlayer
                className="react-player"
                url={"https://s3.ap-south-1.amazonaws.com/trebleindia.com/video-output-BB51EA5B-3529-482A-B693-B867D1808A8A.MOV"}
                controls={true}
              />
            </div>
          </div>
        </section>
        <section id="curriculum" style={{ backgroundColor: "rgb(47, 28, 55)" }}>
          <div className="curriculumDiv0">
            <div className="curriculumDiv1">
              <h1>Curriculum</h1>
            </div>
            <div className="curriculumDiv2">
              <div
                className="curriculumDiv02"
                style={{
                  background: `url(${rookie}) no-repeat center`,
                  backgroundSize: "cover",
                }}
              >
                <div className="curriculumDiv02H1">
                  <h1>Rookie</h1>
                </div>
                <div className="curriculumDiv3">
                  <p>
                    The young enthusiastic learners are given a solid foundation
                    and are equipped to begin the same professionally.
                  </p>
                </div>
              </div>
              <div
                className="curriculumDiv02"
                style={{
                  background: `url(${intermediate}) no-repeat center`,
                  backgroundSize: "45rem",
                  backgroundPositionY: "70%",
                }}
              >
                <div className="curriculumDiv02H1">
                  <h1>Pro</h1>
                </div>
                <div className="curriculumDiv3">
                  <p>The young guns are being groomed to become pros.</p>
                </div>
              </div>
              <div
                className="curriculumDiv02"
                style={{
                  background: `url(${pro}) no-repeat center`,
                  backgroundSize: "40rem",
                  backgroundPositionX: "80%",
                  backgroundPositionY: "85%",
                }}
              >
                <div className="curriculumDiv02H1">
                  <h1>World Class</h1>
                </div>
                <div className="curriculumDiv3">
                  <p>
                    The pros are lead to the top by some of the best in
                    business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Announcement">
        <div className="announcementDiv0" 
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)),url(${announcement}) no-repeat center`,
              backgroundSize: "cover"
            }}
          >
            <div className="announcementDiv1">
              <h1 className="announcementHeader">Hey&nbsp;<span className="announcementHeaderEmphasis">UK</span>&nbsp;,</h1>
              <h1 className="announcementHeader2">Here's one for you.</h1>
            </div>
            <div className="announcementDiv2">
              <ReactPlayer
                className="react-player-2"
                url={"https://s3.ap-south-1.amazonaws.com/trebleindia.com/video-output-33950027-B8AE-4C5C-A449-5E23291FFB4D.MOV"}
                controls={true}
              />
            </div>
            <h2 className="announcementFooter"><span className="announcementHeaderEmphasis">#</span>ComingSoon</h2>
          </div>
        </section>
        <section id="ourTeam">
          <FactsContainer items={items} heading={"Our Team"} />
        </section>
        <div className="lastSection">
          <section id="aboutUs">
            <Services contents={content} />
          </section>
          <section id="contactUs">
            <FooterDiv />
          </section>
        </div>
      </Layout>
    </div>
  );
}

export default App;
