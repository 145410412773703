import doodle2 from '../assets/aboutus.jpg'
import fourth from '../assets/fourth.jpg'
export const Services = ({ contents=""})=>{
    return(
        <div style={{display:"flex", flexDirection:"row", height:"100%"}}>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", width:"100%", height:"100%", backgroundColor:"white",}}>
                <h1 style={{color:"black",fontSize:"xxx-large", WebkitTextStroke:"2px black", fontWeight:"bold", marginBottom:"0"}}>About Us</h1>
                <div className='aboutUsContentParent' style={{display:"flex", flexDirection:"row", alignSelf:"center", fontSize:"medium"}}>
                    <p className='aboutUsContent' style={{color:"rgb(70, 70, 70)", fontWeight:"500"}}>{contents}</p>
                </div>
            </div>
        </div>
    )
}