import {FacebookOutlined, InstagramOutlined, MailOutlined} from "@ant-design/icons"
import { FaMapMarkerAlt } from 'react-icons/fa';
import contactUs from '../assets/contact-us.jpeg'
export const FooterDiv = ()=>{
    return(
        <div style={{display:"flex", flexDirection:"column", width:"100%", height:"100%", justifyContent:"center", alignItems:"center", backgroundImage:`url(${contactUs})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
            <div style={{width:"100%", background:"rgba(0,0,0,0.6)", height:"100%", display:"flex", justifyContent:"space-around", flexDirection:"column", alignItems:"center"}}>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"left", width:"50%", color:"white"}}>
                    <div className='footerAddress'>
                        <p>Kerala, India</p>
                        <p>Kizhakkambalam, Ernakulam</p>
                        <p>NSK Building</p>
                        <p>Mobile:+91-6282882135</p>
                        <p><MailOutlined/> trebleindia@gmail.com</p>
                    </div>
                </div>
                <div style={{width:"50%", justifyContent:"center"}}>
                    <div className="footerLogo">
                        <a style={{color:"white"}} href="https://m.facebook.com/100078581182592/" target="_blank"><FacebookOutlined/></a>
                        <a style={{color:"white"}} href="https://www.instagram.com/trebleindia/?utm_medium=copy_link" target="_blank"><InstagramOutlined/></a>
                        <a href="https://maps.app.goo.gl/2mwNSG44w6HsYd5M6" style={{color:"white"}} target="_blank"><FaMapMarkerAlt/></a>
                    </div>
                </div>
            </div>
        </div>
    )
}