import { Menu } from "antd";
import styled from "styled-components";

export const StyledMenu = styled(Menu)`
    border-bottom:none;
    .ant-menu-item-selected {
        color: rgb(228, 55, 78) !important;
        background-color: rgb(228, 55, 78) !important
    }

    .ant-menu-item:hover {
        color: rgb(233, 76, 98) !important;
        background-color:rgb(211, 65, 85) !important;
    }

    .ant-menu-item:hover::after{
        border-bottom: 2px solid rgb(233, 76, 98) !important;
        /* transition: none !important; */
    }    

    .ant-menu-item-selected::after{
        border-bottom: 2px solid rgb(233, 76, 98) !important;
        /* transition: none !important; */
    }

    .ant-menu-dark .ant-menu-sub{
        background:black;
    }

    .ant-menu-item{
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:x-large
    }

     .ant-menu-submenu{
        align-self:center;
    }

    .ant-menu-dark .ant-menu-sub{
        background:black;
        border:1px solid #4a4a4a;
    }
`